import React from 'react'

const E404 = () => {
  return (
    <><h3>
        Page Not Found
    </h3></>
  )
}

export default E404